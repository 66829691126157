/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~highlight.js/styles/github.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

#app-root {
    height: 100%;
    overflow: hidden;
}

#app-body {
    overflow: hidden;
}

body {
    overflow: hidden;
}

/* Ensure dialog containers appear on top */
.cdk-overlay-container {
    position: fixed;
    z-index: 9999 !important;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.cdk-overlay-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100 !important;
    pointer-events: auto;
    transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1;
}

.cdk-overlay-pane {
    position: absolute;
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 10000 !important;
}

/* Dark theme for dialogs */
.dark-theme-dialog .mat-mdc-dialog-container {
    --mdc-dialog-container-color: #1e1f21 !important;
    color: rgb(162, 160, 162) !important;
}

.dark-theme-dialog .mat-mdc-dialog-surface {
    background-color: #1e1f21 !important;
    color: rgb(162, 160, 162) !important;
    border: 1px solid rgba(255, 255, 255, 0.1);
}